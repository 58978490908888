


























































































































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
// import { AxiosResponse } from 'axios'
import { MenuType } from '@/pages/appointment/types'
import TitleComp from '@/pages/appointment/components/Title.vue'

export type DataType = {
  snackbar: {
    disp: boolean;
    text: string;
    timeout: number;
  };
}

export default Vue.extend({
  name: 'Menu',
  components: {
    TitleComp
  },
  data (): DataType {
    return {
      snackbar: {
        disp: false,
        text: '',
        timeout: 5000
      }
    }
  },
  computed: {
    ...mapGetters('appointments', [
      'initialDiagnosis',
      'selectedMenuIndex',
      'complete',
      'menuList'
    ]),
    selectedIndex: {
      get (): number | null {
        return this.selectedMenuIndex
      },
      set (value: number): void {
        this.setSelectedMenuIndex(value)
      }
    }
  },
  created (): void {
    // const url = `${process.env.VUE_APP_API_ENDPOINT}/api/wrcp/sample-clinic/webappointment/fetch_menu_list/`
    // this.$http
    //   .get(url).then((res: AxiosResponse) => {
    //     this.menuList = res.data.menuList
    //   }).catch((error: Error) => {
    //     this.snackbar.text = error.message
    //     this.snackbar.disp = true
    //   })
    // TODO サンプルデータ（storeにおきたい）
    if (this.menuList.length > 0) {
      // 開閉状態を復元
      this.menuList.forEach((menu: MenuType) => {
        menu.open = menu.openIcon
      })
    } else {
      const menues = [
        {
          id: '1',
          clinic: 1,
          name: '歯が痛い',
          dispOrder: 1,
          open: false,
          openIcon: false,
          subMenuList: [
            {
              id: '2',
              clinic: 1,
              name: '何もしてなくても痛い',
              dispOrder: 1,
              open: false,
              openIcon: false,
              subMenuList: null
            },
            {
              id: '3',
              clinic: 1,
              name: '噛むと痛い',
              dispOrder: 2,
              open: false,
              openIcon: false,
              subMenuList: null
            }
          ]
        },
        {
          id: '4',
          clinic: 1,
          name: '歯茎が痛い・腫れている',
          dispOrder: 2,
          open: false,
          openIcon: false,
          subMenuList: null
        },
        {
          id: '5',
          clinic: 1,
          name: '歯が欠けた・つめ物が取れた',
          dispOrder: 3,
          open: false,
          openIcon: false,
          subMenuList: null
        },
        {
          id: '6',
          clinic: 1,
          name: '歯がしみる',
          dispOrder: 4,
          open: false,
          openIcon: false,
          subMenuList: null
        },
        {
          id: '7',
          clinic: 1,
          name: '歯が動く',
          dispOrder: 5,
          open: false,
          openIcon: false,
          subMenuList: null
        },
        {
          id: '8',
          clinic: 1,
          name: '入れ歯・義歯',
          dispOrder: 6,
          open: false,
          openIcon: false,
          subMenuList: [
            {
              id: '9',
              clinic: 1,
              name: '今の入れ歯の調子が悪い',
              dispOrder: 1,
              open: false,
              openIcon: false,
              subMenuList: null
            },
            {
              id: '10',
              clinic: 1,
              name: '入れ歯を作りたい',
              dispOrder: 2,
              open: false,
              openIcon: false,
              subMenuList: null
            }
          ]
        },
        {
          id: '11',
          clinic: 1,
          name: '歯並びが気になる',
          dispOrder: 7,
          open: false,
          openIcon: false,
          subMenuList: null
        },
        {
          id: '12',
          clinic: 1,
          name: '歯を白くしたい',
          dispOrder: 8,
          open: false,
          openIcon: false,
          subMenuList: null
        },
        {
          id: '13',
          clinic: 1,
          name: '定期検診',
          dispOrder: 9,
          open: false,
          openIcon: false,
          subMenuList: null
        },
        {
          id: '14',
          clinic: 1,
          name: 'お口の悩み相談',
          dispOrder: 10,
          open: false,
          openIcon: false,
          subMenuList: null
        },
        {
          id: '15',
          clinic: 1,
          name: 'その他',
          dispOrder: 11,
          open: false,
          openIcon: false,
          subMenuList: null
        }
      ]
      this.setMenuList(menues)
    }
  },
  methods: {
    ...mapActions('appointments', [
      'setMenu',
      'setSelectedMenuIndex',
      'setMenuList'
    ]),
    change (menu: string): void {
      if (!this.complete) {
        this.setMenu(menu)
        this.$router.push({ name: 'page-date' })
      }
    }
  }
})
